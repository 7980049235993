<template>
  <div class="lald">
    <main>
      <PagesSlider
        v-if="PageContent.banners && PageContent.banners.length"
        :title="PageContent.title"
        :banners="PageContent.banners"
        :color="PageContent.color"
        :noHost="true"
      />
      <section class="therapy-areas failure-page__section">
        <div class="content">
          <div class="failure-page__row">
            <div class="failure-page__categories">
              <div
                class="failure-page__title"
                v-if="PageContent.name"
                v-html="PageContent.name"
              ></div>
              <div
                ref="description"
                class="failure-page__description"
                v-html="PageContent.description"
              ></div>
              <div class="failure-page__categories-row">
                <InfoDrop
                  v-for="(info, ind) in PageContent.content"
                  :key="ind"
                  @clickOpener="clickOpener"
                  :color="PageContent.color"
                  :item="info"
                  :auth="
                    typeof info.auth !== 'undefined'
                      ? info.auth
                      : typeof $route.meta.closed !== 'undefined'
                      ? user &&
                        !Object.keys(user).length &&
                        !$route.query.access
                      : false
                  "
                />
              </div>
            </div>
            <RightAside
              @medicationsClick="medicationsClick"
              @calendarClick="calendarClick"
              @libClick="libClick"
              v-if="
                asideItems && asideItems.length && PageContent.content.length
              "
              :items="asideItems"
              class="failure-page__aside"
            />
          </div>
        </div>
        <PopupEnd
          :eventName="'lald'"
          :backUrl="{ name: 'Lald' }"
          @close="closePopup"
          v-if="popup"
        />
      </section>
    </main>
  </div>
</template>

<script>
import AsideBlock from "@/components/pageComponents/AsideBlock.vue";
import Breadcrumbs from "@/components/pageComponents/Breadcrumbs.vue";
import InfoDrop from "@/components/pageComponents/InfoDrop.vue";
import PagesSlider from "@/components/main/PagesSlider.vue";
import RightAside from "@/components/pageComponents/RightAside.vue";
import PopupEnd from "@/components/pageComponents/PopUpEventEnd.vue";
import { mapGetters } from "vuex";

export default {
  metaInfo() {
    return { title: "Дефицит лизосомной кислой липазы: Вопросы и ответы" };
  },
  components: {
    Breadcrumbs,
    AsideBlock,
    InfoDrop,
    PagesSlider,
    RightAside,
    PopupEnd,
  },
  name: "Gff",
  data: (vm) => ({
    items: [],
    index: null,
    popup: false,
    media: {
      mobile: 767,
      tablet: 1220,
      desktop: 1919,
      desktop_l: 2559,
      desktop_xl: 3839,
      desktop_xxl: 9999999,
    },
  }),
  computed: {
    ...mapGetters(["user"]),
    asideItems() {
      if (this.PageContent && Object.keys(this.PageContent).length) {
        return [
           {
            title: "Препараты АЗ",
            // descr: this.Disease.parent.title,
            image_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-1_small.jpg`,
            image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-1.jpg`,
            theme: "white",
            event: "medicationsClick",
            to: {
              name: "Medications",
              query: {
                therapeutic_areas: "rare-diseases",
                disease: "dlkl",
              },
            },
          },
          {
            title: "Мероприятия",
            image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-2.jpg`,
            image_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-2_small.jpg`,
            links: [
              {
                event: "calendarClick",
                title: "Предстоящие",
                to: {
                  name: "Events",
                  query: {
                    therapeutic_areas: "rare-diseases",
                  },
                },
              },
              {
                event: "calendarClick",
                title: "Архив",
                to: {
                  name: "ArchiveEvents",
                  query: {
                    therapeutic_areas: "rare-diseases",
                    disease: "dlkl",
                  },
                },
              },
            ],
          },
          {
            title: "Библиотека",
            image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-3.jpg`,
            image_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-3_small.jpg`,
            links: [
              {
                event: "libClick",
                title: "Публикации",
                to: {
                  name: "TextMaterials",
                },
              },
              {
                event: "libClick",
                title: "Видеоматериалы",
                to: {
                  name: "VideoMaterials",
                },
              },
              {
                event: "libClick",
                title: "Подкасты",
                to: {
                  name: "Podcasts",
                },
              },
            ],
            theme: "dark",
          },
        ];
      } else {
        return [];
      }
    },
    PageContent() {
      return {
        id: 11,
        content: [
          {
            title: "Что такое ДЛКЛ?",
            content: `<div class='text-1'>Дефицит лизосомной кислой липазы - прогрессирующее наследственное заболевание, в основе которого лежит дефект гена LIPA, кодирующего фермент лизосомную кислую липазу, приводящий к накоплению эфиров холестерина и триглицеридов в органах и тканях. Выделяют две основные формы ДЛКЛ:
              <br>
              <br>
              - инфантильная (болезнь Вольмана) — с манифестацией в первые 6 месяцев жизни
              <br>
              - болезнь накопления эфиров холестерина (БНЭХ), с дебютом в возрасте старше 6 месяцев<sup>1</sup>
              </div>`,
            content_above: "",
            active: true,
            auth: false,
          },
          {
            title: "Какая эпидемиология заболевания? ",
            content:
              "<div class='text-1'>Распространенность ДЛКЛ вариабельна в зависимости от факторов этнической принадлежности и географического положения. Считается, что частота ДЛКЛ составляет в среднем 1:40000-1:300000 живых новорожденных.<sup>1</sup>",
            content_above: "",
            active: true,
            auth: false,
          },
          {
            title: "Какая клиническая картина ДЛКЛ?",
            content: `<div class='text-1'>ДЛКЛ является <b>мультисистемным заболеванием</b>, симптомы могут быть обусловлены поражением различных органов. Основными органами-мишенями являются <b>печень, селезенка, ЖКТ, почки, сосуды</b>.  
              <br>
              <br>
              Заболевание проявляется гепатомегалией (больше за счет правой доли печени), связанной с накоплением эфиров холестерина в гепатоцитах и клетках Купфера, которая определяется у 99% пациентов, синдромом цитолиза, дислипидемией. По мере прогрессирования заболевания формируется фиброз и цирроз печени с развитием печеночной недостаточности.
              <br>
              <br>
              Увеличение селезенки обусловлено отложением эфиров холестерина в макрофагах и прогрессированием цирроза печени с формированием портальной гипертензии, что сопровождается нарастанием признаков гиперспленизма (анемия, лейкопения, тромбоцитопения). Спленомегалия определяется у 74% пациентов.
              <br>
              <br>
              Дислипидемия проявляется повышением в сыворотке крови уровня общего холестерина, холестерина ЛПНП и триглицеридов при нормальном или низком уровне холестерина ЛПВП (гиперлипопротеинемия IIb типа), что может приводить к ускоренному развитию атеросклероза. Гиперхолестеринемия определяется у 81% пациентов с БНЭХ, увеличение концентрации холестерина ЛПНП — у всех пациентов. 
              <br>
              <br>
              Синдром цитолиза — наблюдается практически у всех пациентов и часто служит одним из первых проявлений заболевания. 
              <br>
              <br>
              Из-за вовлечения в патологический процесс кишечника у части больных может наблюдаться синдром мальабсорбции (недостаточность питания, диарея, стеаторея, нарушение всасыванияжирорастворимых нутриентов).
              <br>
              <br>
              Таким образом, ДЛКЛ следует заподозрить у молодых пациентов с гепатомегалией, увеличением АЛТ, АСТ в сыворотке крови, у которых также может иметь место повышение уровня общего холестерина и холестерина ЛПНП вместе с небольшим или умеренным снижением уровня холестерина ЛПВП.<sup>1</sup></div>`,
            content_above: "",
            active: true,
            auth: false,
          },
          {
            title: "Какую роль играет фермент ЛКЛ?",
            content: `<div class='text-1'>Лизосомная кислая липаза –  фермент, который отвечает за метаболизм холестерина. В норме ЛКЛ находится в организме в достаточном количестве для обеспечения метаболизма холестерина. При отсутствии или снижении активности ЛКЛ, эфиры холестерина и триглицериды не распадаются и накапливаются в лизосомах во всех системах органов, что приводит к постоянному риску опасных для жизни полиорганных поражений.<sup>1,2</sup></div>`,
            content_above: "",
            active: true,
          },
          {
            title: "Как устанавливают в лаборатории диагноз ДЛКЛ?",
            content: `<div class='text-1'>Диагноз ДЛКЛ устанавливается на основании совокупности анамнестических и клинических данных, результатов лабораторных, инструментальных, морфологических методов исследований. 
              <br>
              <br>
              Определение активности <b>ЛКЛ является «золотым стандартом» диагностики</b> этого заболевания. Выявление сниженной активности ЛКЛ позволяет подтвердить диагноз. У пациентов с ДЛКЛ активность ЛКЛ снижена в десятки и сотни раз, а иногда, при тяжелых формах, вовсе отсутствует. 
              <br>
              <br>
              Если активность фермента снижена, то проводится ДНК-диагностика. В результате которой выявляют мутации в гене <i>LIPA</i>. При этом заболевании у пациента выявляют <b>две мутации в гене</b>. Они могут быть в гомозиготном состоянии или в компаунд-гетерозиготном состоянии.<sup>1</sup></div>`,
            content_above: "",
            active: true,
          },
          {
            title:
              "Существует ли программа диагностики для исключения заболевания ДЛКЛ?",
            content: `<div class='text-1'>Да, для исключения диагноза ДЛКЛ проводится бесплатная и добровольная программа диагностики на базе лаборатории ООО «Гемотест» совместно с ФГБУ «Медико-генетического научного центра имени академика Н.П. Бочкова» и с ФГАУ «НМИЦ здоровья детей», г. Москва. Программа осуществляется при поддержке ООО «Астразенека Фармасьютикалз» и не финансируется из средств ОМС</div>`,
            content_above: "Программа диагностики ДЛКЛ ",
            active: true,
          },
          {
            title: "Кого я могу направить пациента на диагностику ДЛКЛ?",
            content: `<div class='text-1'>Направление на участие в программе выдается представителю пациента (ребенка до 18 лет, включительно) в случае наличия у пациента <b>увеличения размера печени (пальпируемого или выявленного при УЗИ-обследовании)</b>`,
            content_above: "",
            active: true,
          },
          {
            title:
              "Детей с увеличением печени много, каких направлять на диагностику ДЛКЛ?",
            content: `<div class='text-1'>В рамках программы есть возможность направлять пациентов с увеличением печени <b>неинфекцонной этиологии</b>.</div>`,
            content_above: "",
            active: true,
          },
          {
            title: "Какие анализы входят в программу диагностики ДЛКЛ?",
            content: `<div class='text-1'>В рамках программы пациенту оказываются следующие услуги:
              <br>
              <br>
              1. Определение уровней АЛТ, АСТ, ЛПНП – <i>срок выполнения до 3 дней</i>
              <br>
              2. Определение активности лизосомной кислой липазы (проводится только в случае выявления увеличения одного или нескольких показателей – АЛТ, АСТ и/или ЛПНП) – <i>срок выполнения до 15 дней</i>
              <br>
              3. Анализ гена LIPA (проводится только в случае выявления сниженной активности лизосомной кислой липазы) – <i>срок выполнения до 1 месяца</i>
              <br>
              4. Телемедицинская консультация с генетиком (проводится только в случае обнаружения мутации гена LIPA) – <i>в течение 1 месяца с представителем свяжется оператор горячей линии Гемотест для записи на онлайн консультацию</i>

              </div>`,
            content_above: "",
            active: true,
          },
          {
            title: "Где пациент будет сдавать кровь на анализ?",
            content: `<div class='text-1'>В выделенных офисах ГЕМОТЕСТ
              <br>
              <br>
              Адреса отделений ГЕМОТЕСТ уточняйте на горячей линии ГЕМОТЕСТ 8-800-550-13-13, доб. 69777.</div>`,
            content_above: "",
            active: true,
          },
          {
            title: "Какие лаборатории будут проводить генетический анализ?",
            content:
              "<div class='text-1'>Генетический анализ на поиск изменений в гене LIPA будет проведен на базе федерального центра МГНЦ им. Бочкова.</div>",
            content_above: "",
            active: true,
          },
          {
            title: "Как я узнаю о результате анализа?",
            content:
              "<div class='text-1'>Результаты анализа будут доступны пациенту по email, в личном кабинете ГЕМОТЕСТ или в отделении ГЕМОТЕСТ (при предъявлении документа, удостоверяющего личность).</div>",
            content_above: "",
            active: true,
          },
          {
            title: "Что происходит после положительного результата анализов?",
            content:
              "<div class='text-1'>При положительном результате на ДЛКЛ пациент будет проходить консультацию у врача-генетика ГЕМОТЕСТ.</div>",
            content_above: "",
            active: true,
          },

          {
            title: "Список литературы",
            content: `<div class="text-1">
  ДЛКЛ – дефицит лизосомной кислой липазы, 
  <br>ЛКЛ – лизосомная кислая липаза, 
  <br>ЛПВП - липопротеины высокой плотности, 
  <br>ЛПНП - липопротеины низкой плотности
  <br>
  <br>
  <ol>
    <li>
  Клинические рекомендации «Другие нарушения накопления липидов (Дефицит лизосомой кислой липазы)», 2023 <a href="https://cr.minzdrav.gov.ru/schema/354_2" target="_blank">https://cr.minzdrav.gov.ru/schema/354_2</a> Дата обращения: 31.01.2025.
</li>
<li>Hoffman EP, Barr ML, Giovanni MA, et al. Lysosomal Acid Lipase Deficiency. 2015 Jul 30 [Updated 2016 Sep 1]. In: Adam MP, ArdingerHH, PagonRA, et al., editors. GeneReviews® [Internet]. Seattle (WA): University of Washington, Seattle; 1993–2020. Available from: <a href="https://www.ncbi.nlm.nih.gov/books/NBK305870/" target="_blank">https://www.ncbi.nlm.nih.gov/books/NBK305870/</a>  .</li>
  </ol>
</div>`,
            content_above: "",
            active: true,
          },
        ],
        banners: [
          {
            banner_mobile: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/hpp/hpp_375.jpg`,
            banner_tablet: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/hpp/hpp_768.jpg`,
            banner_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/hpp/hpp_1440.jpg`,
            banner_desktop_l: null,
            banner_desktop_xl: null,
            banner_desktop_xxl: null,
            title: "Дефицит лизосомной кислой липазы: Вопросы и ответы",
            description: "",
          },
        ],
        title: "Дефицит лизосомной кислой липазы: Вопросы и ответы",
        name: "О нозологии",
        color: "#830051",
      };
    },
  },
  methods: {
    medicationsClick(item) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "disease page medications click", {
          "disease page medications click": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    closePopup() {
      this.popup = false;
    },
    clickOpener() {
      this.popup = true;
    },
    calendarClick(item) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "disease page events click", {
          "disease page events click": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    libClick(item, text) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "disease page library materails click", {
          "disease page library materails click": {
            "block name": text,
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.materials {
  display: grid;

  margin-bottom: 40px;

  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.failure-page {
  &__categories {
    max-width: 800px;
  }
  &__row {
    display: grid;

    column-gap: 136px;
    grid-template-columns: 2fr 280px;
    @media screen and (max-width: 1220px) {
      grid-template-columns: 100%;
      row-gap: 40px;
    }
    .failure-page__aside {
      @media screen and (max-width: 1220px) {
        display: flex;
        overflow-x: auto;
        align-items: center;
        justify-content: flex-start;

        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
      }
    }
  }
  &__section {
    padding-top: 64px;
  }
  &__title {
    margin-bottom: 40px;
    color: #1f1f1f;
    font-family: "Roboto Slab", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 42px;
    line-height: 48px;
    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
    }
  }
  // &__title_big {
  //   margin-bottom: 40px;
  //   color: #1f1f1f;
  //   font-family: "Roboto Slab", sans-serif;
  //   font-weight: 400;
  //   font-style: normal;
  //   font-size: 42px;
  //   line-height: 48px;
  //   @media screen and (max-width: 767px) {
  //     font-size: 32px;
  //     line-height: 38px;
  //   }
  // }
  &__description {
    color: #656969;

    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;
  }
}
</style>

<style lang="scss">
.lald i {
  font-style: italic !important;
}
</style>
