var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lald"},[_c('main',[(_vm.PageContent.banners && _vm.PageContent.banners.length)?_c('PagesSlider',{attrs:{"title":_vm.PageContent.title,"banners":_vm.PageContent.banners,"color":_vm.PageContent.color,"noHost":true}}):_vm._e(),_c('section',{staticClass:"therapy-areas failure-page__section"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"failure-page__row"},[_c('div',{staticClass:"failure-page__categories"},[(_vm.PageContent.name)?_c('div',{staticClass:"failure-page__title",domProps:{"innerHTML":_vm._s(_vm.PageContent.name)}}):_vm._e(),_c('div',{ref:"description",staticClass:"failure-page__description",domProps:{"innerHTML":_vm._s(_vm.PageContent.description)}}),_c('div',{staticClass:"failure-page__categories-row"},_vm._l((_vm.PageContent.content),function(info,ind){return _c('InfoDrop',{key:ind,attrs:{"color":_vm.PageContent.color,"item":info,"auth":typeof info.auth !== 'undefined'
                    ? info.auth
                    : typeof _vm.$route.meta.closed !== 'undefined'
                    ? _vm.user &&
                      !Object.keys(_vm.user).length &&
                      !_vm.$route.query.access
                    : false},on:{"clickOpener":_vm.clickOpener}})}),1)]),(
              _vm.asideItems && _vm.asideItems.length && _vm.PageContent.content.length
            )?_c('RightAside',{staticClass:"failure-page__aside",attrs:{"items":_vm.asideItems},on:{"medicationsClick":_vm.medicationsClick,"calendarClick":_vm.calendarClick,"libClick":_vm.libClick}}):_vm._e()],1)]),(_vm.popup)?_c('PopupEnd',{attrs:{"eventName":'lald',"backUrl":{ name: 'Lald' }},on:{"close":_vm.closePopup}}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }